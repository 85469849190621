var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
    _c("div", { staticClass: "jumbotron m-0 p-0 p-3 jumbotron-fluid" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "h1",
                { staticClass: "text-secondary display-5 mb-2 mb-md-3" },
                [_vm._v(" " + _vm._s(_vm.$t("menu.question")) + " ")]
              )
            ])
          ]),
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                }
              }
            },
            [
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "satisfaction-input-group",
                            label:
                              "In che misura sei soddisfatto del servizio di badante fornito?",
                            "label-for": "satisfaction"
                          }
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              attrs: {
                                id: "satisfaction",
                                state: _vm.validateState("satisfaction"),
                                "aria-describedby": "satisfaction-live-feedback"
                              },
                              model: {
                                value: _vm.$v.form.satisfaction.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.form.satisfaction,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.form.satisfaction.$model"
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v("- Seleziona -")
                              ]),
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v("Molto soddisfatto")
                              ]),
                              _c("option", { attrs: { value: "2" } }, [
                                _vm._v("Soddisfatto")
                              ]),
                              _c("option", { attrs: { value: "3" } }, [
                                _vm._v("Moderatamente soddisfatto")
                              ]),
                              _c("option", { attrs: { value: "4" } }, [
                                _vm._v("Insoddisfatto")
                              ]),
                              _c("option", { attrs: { value: "5" } }, [
                                _vm._v("Molto insoddisfatto")
                              ])
                            ]
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "satisfaction-live-feedback" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("validation.required")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "punctuality-input-group",
                            label:
                              "La badante assegnata è stata puntuale ed efficiente nello svolgimento delle sue mansioni?",
                            "label-for": "punctuality"
                          }
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "punctuality",
                              state: _vm.validateState("punctuality"),
                              "aria-describedby": "punctuality-live-feedback",
                              options: _vm.options,
                              name: "punctuality"
                            },
                            model: {
                              value: _vm.$v.form.punctuality.$model,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.form.punctuality, "$model", $$v)
                              },
                              expression: "$v.form.punctuality.$model"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "punctuality-live-feedback" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("validation.required")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "courtesy-input-group",
                            label:
                              "La badante assegnata si è mostrata gentile e rispettosa nei confronti della persona assistita?",
                            "label-for": "courtesy"
                          }
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "courtesy",
                              state: _vm.validateState("courtesy"),
                              "aria-describedby": "courtesy-live-feedback",
                              options: _vm.options,
                              name: "courtesy"
                            },
                            model: {
                              value: _vm.$v.form.courtesy.$model,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.form.courtesy, "$model", $$v)
                              },
                              expression: "$v.form.courtesy.$model"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "courtesy-live-feedback" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("validation.required")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "communication-input-group",
                            label:
                              "Sei stato soddisfatto della comunicazione con il nostro personale durante il processo di assegnazione della badante?",
                            "label-for": "communication"
                          }
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "communication",
                              state: _vm.validateState("communication"),
                              "aria-describedby": "communication-live-feedback",
                              options: _vm.options,
                              name: "communication"
                            },
                            model: {
                              value: _vm.$v.form.communication.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.form.communication,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.form.communication.$model"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "communication-live-feedback" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("validation.required")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "price-input-group",
                            label:
                              "Ritieni che il prezzo del servizio sia stato equo in rapporto alla qualità del servizio ricevuto?",
                            "label-for": "price"
                          }
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "price",
                              state: _vm.validateState("price"),
                              "aria-describedby": "price-live-feedback",
                              options: _vm.options,
                              name: "price"
                            },
                            model: {
                              value: _vm.$v.form.price.$model,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.form.price, "$model", $$v)
                              },
                              expression: "$v.form.price.$model"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "price-live-feedback" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("validation.required")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "repeat-input-group",
                            label:
                              "Saresti disposto a utilizzare nuovamente il nostro servizio di badanti in futuro?",
                            "label-for": "repeat"
                          }
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "repeat",
                              state: _vm.validateState("repeat"),
                              "aria-describedby": "repeat-live-feedback",
                              options: _vm.options,
                              name: "repeat"
                            },
                            model: {
                              value: _vm.$v.form.repeat.$model,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.form.repeat, "$model", $$v)
                              },
                              expression: "$v.form.repeat.$model"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "repeat-live-feedback" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("validation.required")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "comments-input-group",
                            label:
                              "Ci sono altri commenti o suggerimenti che vorresti condividere in merito al servizio di badanti offerto dal nostro portale?",
                            "label-for": "comments"
                          }
                        },
                        [
                          _c("b-textarea", {
                            attrs: {
                              rows: "5",
                              id: "comments",
                              state: _vm.validateState("comments"),
                              "aria-describedby": "comments-live-feedback"
                            },
                            model: {
                              value: _vm.$v.form.comments.$model,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.form.comments, "$model", $$v)
                              },
                              expression: "$v.form.comments.$model"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "comments-live-feedback" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("validation.required")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { md: "12" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "success" } },
                        [_vm._v("Invia risposte")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "ml-2", on: { click: _vm.goToList } },
                        [_vm._v("No, grazie")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }