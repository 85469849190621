<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="jumbotron m-0 p-0 p-3 jumbotron-fluid">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="text-secondary display-5 mb-2 mb-md-3">
              {{ $t("menu.question") }}
            </h1>
          </div>
        </div>
        <b-form @submit.stop.prevent="onSubmit">
          <!-- livello soddisfazione -->
          <b-form-row>
            <b-col>
              <b-form-group
                id="satisfaction-input-group"
                label="In che misura sei soddisfatto del servizio di badante fornito?"
                label-for="satisfaction"
              >
                <b-form-select
                  v-model="$v.form.satisfaction.$model"
                  id="satisfaction"
                  :state="validateState('satisfaction')"
                  aria-describedby="satisfaction-live-feedback"
                >
                  <option :value="null">- Seleziona -</option>
                  <option value="1">Molto soddisfatto</option>
                  <option value="2">Soddisfatto</option>
                  <option value="3">Moderatamente soddisfatto</option>
                  <option value="4">Insoddisfatto</option>
                  <option value="5">Molto insoddisfatto</option>
                </b-form-select>
                <b-form-invalid-feedback id="satisfaction-live-feedback"
                  >{{ $t("validation.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- puntualità -->
          <b-form-row>
            <b-col>
              <b-form-group
                id="punctuality-input-group"
                label="La badante assegnata è stata puntuale ed efficiente nello svolgimento delle sue mansioni?"
                label-for="punctuality"
              >
                <b-form-radio-group
                  id="punctuality"
                  v-model="$v.form.punctuality.$model"
                  :state="validateState('punctuality')"
                  aria-describedby="punctuality-live-feedback"
                  :options="options"
                  name="punctuality"
                ></b-form-radio-group>
                <b-form-invalid-feedback id="punctuality-live-feedback"
                  >{{ $t("validation.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- cortesia -->
          <b-form-row>
            <b-col>
              <b-form-group
                id="courtesy-input-group"
                label="La badante assegnata si è mostrata gentile e rispettosa nei confronti della persona assistita?"
                label-for="courtesy"
              >
                <b-form-radio-group
                  id="courtesy"
                  v-model="$v.form.courtesy.$model"
                  :state="validateState('courtesy')"
                  aria-describedby="courtesy-live-feedback"
                  :options="options"
                  name="courtesy"
                ></b-form-radio-group>
                <b-form-invalid-feedback id="courtesy-live-feedback"
                  >{{ $t("validation.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- comunicazione -->
          <b-form-row>
            <b-col>
              <b-form-group
                id="communication-input-group"
                label="Sei stato soddisfatto della comunicazione con il nostro personale durante il processo di assegnazione della badante?"
                label-for="communication"
              >
                <b-form-radio-group
                  id="communication"
                  v-model="$v.form.communication.$model"
                  :state="validateState('communication')"
                  aria-describedby="communication-live-feedback"
                  :options="options"
                  name="communication"
                ></b-form-radio-group>
                <b-form-invalid-feedback id="communication-live-feedback"
                  >{{ $t("validation.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- prezzo -->
          <b-form-row>
            <b-col>
              <b-form-group
                id="price-input-group"
                label="Ritieni che il prezzo del servizio sia stato equo in rapporto alla qualità del servizio ricevuto?"
                label-for="price"
              >
                <b-form-radio-group
                  id="price"
                  v-model="$v.form.price.$model"
                  :state="validateState('price')"
                  aria-describedby="price-live-feedback"
                  :options="options"
                  name="price"
                ></b-form-radio-group>
                <b-form-invalid-feedback id="price-live-feedback"
                  >{{ $t("validation.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                id="repeat-input-group"
                label="Saresti disposto a utilizzare nuovamente il nostro servizio di badanti in futuro?"
                label-for="repeat"
              >
                <b-form-radio-group
                  id="repeat"
                  v-model="$v.form.repeat.$model"
                  :state="validateState('repeat')"
                  aria-describedby="repeat-live-feedback"
                  :options="options"
                  name="repeat"
                ></b-form-radio-group>
                <b-form-invalid-feedback id="repeat-live-feedback"
                  >{{ $t("validation.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- commenti -->
          <b-form-row>
            <b-col>
              <b-form-group
                id="comments-input-group"
                label="Ci sono altri commenti o suggerimenti che vorresti condividere in merito al servizio di badanti offerto dal nostro portale?"
                label-for="comments"
              >
                <b-textarea
                  v-model="$v.form.comments.$model"
                  rows="5"
                  id="comments"
                  :state="validateState('comments')"
                  aria-describedby="comments-live-feedback"
                ></b-textarea>
                <b-form-invalid-feedback id="comments-live-feedback"
                  >{{ $t("validation.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12" class="text-right">
              <b-button type="submit" variant="success"
                >Invia risposte</b-button
              >
              <b-button class="ml-2" @click="goToList">No, grazie</b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import i18n from "@/i18n";
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Question",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.question"),
    meta: [{ name: "description", content: i18n.t("menu.question") }],
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      loading: "serviceRequests/loadingServiceRequested",
    }),
  },
  validations: {
    form: {
      satisfaction: {
        required,
      },
      punctuality: {
        required,
      },
      courtesy: {
        required,
      },
      communication: {
        required,
      },
      price: {
        required,
      },
      repeat: {
        required,
      },
      comments: {},
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
      options: [
        { text: "Sì", value: "yes" },
        { text: "No", value: "no" },
      ],
    };
  },
  methods: {
    ...mapActions({
      showSuccess: "alert/success",
      saveQuestion: "serviceRequests/question",
    }),
    getDefaultFormData() {
      return {
        satisfaction: null,
        punctuality: null,
        courtesy: null,
        communication: null,
        price: null,
        repeat: null,
        comments: null,
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      await this.saveQuestion({
        ...this.form,
        id_richiesta_servizio: this.id,
      });
      this.showSuccess("Risposte inviate con successo");
      this.goToList();
    },
    goToList() {
      router.push({
        name: "service_requests",
      });
    },
  },
};
</script>
